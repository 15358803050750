import React, { memo, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import isMobile from 'ismobilejs'
import { Icon, SLogo, ICONS, Button } from '@sellpy/design-system-react-web'
import { DateTime } from 'luxon'
import { matchRoutes, useLocation } from 'react-router-dom'
import { A, H2, P, SIZES } from '../../../uiComponents'

const LOCAL_STORAGE_KEY = 'appDownloadBannerDismissedAt'

const APP_DOWNLOAD_BANNER_HEIGHT = 72

const getAppLink = () => {
  if (isMobile.android.phone)
    return 'intent:sellpy://#Intent;package=com.sellpy.sellpy;scheme=sellpy://;end'
  if (isMobile.apple.phone) return `itms-apps://itunes.apple.com/app/id1594599102`
  return null
}

const isMoreThanAMonthAgo = (isoDate) => {
  const givenDate = DateTime.fromISO(isoDate)
  const currentDate = DateTime.utc()
  const differenceInMonths = currentDate.diff(givenDate, 'months').months
  return differenceInMonths > 1
}

const shouldHideAppBanner = (location) => {
  const routesToMatch = [{ path: '/sale/:id', end: true }]
  const matches = matchRoutes(routesToMatch, location)
  return Boolean(matches)
}

const displayBanner = (location) => {
  const dismissedAt = window.localStorage.getItem(LOCAL_STORAGE_KEY)
  const dismissedMoreThanOneMonthAgo = dismissedAt && isMoreThanAMonthAgo(dismissedAt)
  return (
    getAppLink() && (!dismissedAt || dismissedMoreThanOneMonthAgo) && !shouldHideAppBanner(location)
  )
}

const useBannerVisibility = () => {
  const location = useLocation()
  const show = displayBanner(location)
  const [shouldShow, setShouldShow] = useState(show)

  useEffect(() => {
    setShouldShow(show)
  }, [location])

  const hide = useCallback(() => {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, new Date().toISOString())
    setShouldShow(false)
  }, [])
  return { shouldShow, hide }
}

const Wrapper = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.color.grey.shade8};
  padding: 16px 0px 16px 16px;
  display: flex;
  justify-content: stretch;
  align-items: center;
  gap: 8px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
  overflow: hidden;
  max-width: 100%;
`

const Logo = styled(SLogo)`
  height: 40px;
  width: 40px;
`

const Title = styled(H2)`
  color: ${({ theme }) => theme.color.grey.shade1};
`

const SubTitle = styled(P)`
  color: ${({ theme }) => theme.color.grey.shade3};
  display: inline-block;
  white-space: nowrap;
`
const SubTitleWrapper = styled.div`
  overflow: hidden;
  &::before {
    content: '';
    display: inline-block;
  }
`

const StyledA = styled(A)`
  flex-grow: 0;
`

const CloseButton = styled.button`
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  color: ${({ theme }) => theme.color.black.default};
`

const AppDownloadBanner = memo(({ onHide = () => {} }) => {
  const { t } = useTranslation('common')
  const appLink = getAppLink()
  if (!appLink) return null
  return (
    <Wrapper>
      <Logo />
      <TextContainer>
        <Title design='body4' noMargin>
          {t('appDownloadBanner.title')}
        </Title>
        <SubTitleWrapper>
          <SubTitle design='body5' noMargin>
            {t('appDownloadBanner.subtitle')}
          </SubTitle>
        </SubTitleWrapper>
      </TextContainer>
      <StyledA href={appLink}>
        <Button variant='outlined' size={SIZES.SMALL} label={t('appDownloadBanner.download')} />
      </StyledA>
      <CloseButton aria-label='Close banner' role='button' onClick={onHide}>
        <Icon name={ICONS.CLOSE} style={{ fontSize: '14px' }} />
      </CloseButton>
    </Wrapper>
  )
})
AppDownloadBanner.displayName = 'AppDownloadBanner'

const HideableWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  max-height: ${({ isOpen }) => (isOpen ? APP_DOWNLOAD_BANNER_HEIGHT : 0)}px;
  transition: max-height 0.5s ease;
`

/**
 * Delay (in px) between the banner switching state (isOpen).
 *
 * For example, if value is 100px and the banner's isOpen state has JUST changed true->false,
 * the user has to scroll upwards by 100px for the banner to show again.
 */
const SCROLL_THRESHOLD = APP_DOWNLOAD_BANNER_HEIGHT

const HideableAppDownloadBanner = () => {
  const { shouldShow, hide } = useBannerVisibility()
  const [isOpen, setIsOpen] = useState(shouldShow)

  useEffect(() => {
    let lastScrollY = window.scrollY
    let updateInProgress = false // prevents onScroll from firing too many update requests

    const updateScrollState = () => {
      const currentScrollY = window.scrollY
      if (Math.abs(currentScrollY - lastScrollY) > SCROLL_THRESHOLD) {
        const shouldBeOpen = currentScrollY < lastScrollY
        if (shouldBeOpen !== isOpen) setIsOpen(shouldBeOpen)
        lastScrollY = currentScrollY > 0 ? currentScrollY : 0
      }
      updateInProgress = false
    }

    const onScroll = () => {
      if (!updateInProgress) {
        window.requestAnimationFrame(updateScrollState)
        updateInProgress = true
      }
    }

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [isOpen, setIsOpen])

  if (!shouldShow) return null
  return (
    <HideableWrapper isOpen={isOpen}>
      <AppDownloadBanner onHide={hide} />
    </HideableWrapper>
  )
}
HideableAppDownloadBanner.displayName = 'HideableAppDownloadBanner'

export default memo(HideableAppDownloadBanner)
