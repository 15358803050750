/* global fetch */
import Parse from 'parse'
import { ApolloClient, HttpLink, from, ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/react'
import cache from './cache'

// Log any GraphQL errors that occurred
export const errorLink = onError(({ networkError, graphQLErrors, operation }) => {
  if (networkError?.result?.code === 209) {
    const message = `[Network error]: Description: user has been logged out, Code: ${networkError?.result?.code}, message, ${networkError?.result?.error}`
    process.env.NODE_ENV === 'production' ? Sentry.captureMessage(message) : console.error(message)
    return
  }

  if (graphQLErrors)
    graphQLErrors.forEach((error) => {
      if (process.env.NODE_ENV === 'production') {
        console.error(
          `[GraphQL error]: Operation: ${operation?.operationName}, Message: ${error.message}`
        )
      } else if (error?.extensions?.code === 999) {
        /**
         * Internal parsoku errors should not be sent to Sentry
         */
      } else {
        Sentry.withScope((scope) => {
          scope.setTag('query', operation?.operationName)
          scope.setTag('code', error?.extensions?.code)
          scope.setTag('kind', operation?.query?.kind)
          scope.setFingerprint([operation?.operationName, error?.extensions?.code, error?.message])
          if (error?.extensions?.sellpyCode)
            scope.setTag('sellpyCode', error?.extensions?.sellpyCode)
          if (operation?.variables) scope.setExtras(operation?.variables)
          if (error?.path?.toString()) scope.setExtra('path', error?.path?.toString())
          if (error?.extensions?.details) scope.setExtras(error?.extensions?.details)
          if (error?.message) {
            scope.setExtra('message', error?.message)
            Sentry.captureException(new Error(error?.message))
          }
          if (error?.message?.details) scope.setExtra('details', error?.message?.details)
        })
      }
    })
})

const withInstallationId = (applicationId) =>
  setContext(async (_, { headers }) => {
    const installationId = window.localStorage.getItem(`Parse/${applicationId}/installationId`)
    if (installationId) {
      return {
        headers: { 'X-Parse-Installation-Id': installationId, ...headers }
      }
    }
    return { headers }
  })

const withAuth = setContext(async (_, { headers }) => {
  if (Parse.User.current()) {
    return {
      headers: { 'X-Parse-Session-Token': Parse.User.current().getSessionToken(), ...headers }
    }
  } else {
    return { headers }
  }
})

const withParseSetup = (apolloConfig) =>
  from([
    withInstallationId(Parse.applicationId),
    withAuth,
    errorLink,
    new HttpLink({
      // Operation name parameter is to improve searchablility in the network tab and logs
      uri: ({ operationName }) => `${apolloConfig.uri}?_=${operationName}`,
      headers: {
        'X-Parse-Application-Id': Parse.applicationId,
        'Content-Type': 'application/json'
      },
      fetch,
      fetchOptions: {
        method: 'POST'
      }
    })
  ])

const withSanityOrParse = ({ sanityConfig, apolloConfig }) =>
  // Go directly to sanity API (not CDN) if preview is enabled
  ApolloLink.split(
    (operation) => operation.getContext().api === 'sanity' && operation.getContext().preview,
    new HttpLink({
      uri: `https://${sanityConfig.projectId}.api.sanity.io/v1/graphql/${sanityConfig.dataset}/${sanityConfig.version}`,
      fetch,
      fetchOptions: {
        method: 'POST'
      },
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip'
      },
      credentials: 'include'
    }),
    // Otherwise, go to sanity CDN
    ApolloLink.split(
      (operation) => operation.getContext().api === 'sanity' && !operation.getContext().preview,
      new HttpLink({
        uri: `https://${sanityConfig.projectId}.apicdn.sanity.io/v1/graphql/${sanityConfig.dataset}/${sanityConfig.version}`,
        fetch
      }),
      // Otherwise, go to Parse
      withParseSetup(apolloConfig)
    )
  )

export const initializeApolloClient = ({ sanityConfig, apolloConfig }) => {
  const apolloClient = new ApolloClient({
    link: withSanityOrParse({ sanityConfig, apolloConfig }),
    cache,
    connectToDevTools: true,
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all'
      }
    }
  })
  return apolloClient
}
